const arrDomain: string[] = ['dev', 'test', 'staging', 'localhost'];
// Lấy domain
// const hostnameArr: string[] = location?.hostname?.split('.');
const hostnameArr: string[] = ['test'];
hostnameArr.push('youmed.vn');
arrDomain.push('youmed.vn');
const domain: string | undefined = hostnameArr.find(x => arrDomain.includes(x));

const findFlag: any = arrDomain.find(x => domain?.includes(x));
let listUrlApi = {
  isShareAuthOrLang: false,
  url: '',
  cdn: '',
  apiUrl: '',
  adminApiUrl: '',
  returnUrlHome: '',
  returnUrlBooking: '',
  jsonUrl: '',
  urlSocket: '',
  chatDomain: '',
};

switch (findFlag) {
  case 'test':
    listUrlApi = {
      isShareAuthOrLang: false,
      url: `https://youmed.test.davido.vn`,
      cdn: `https://cdn.test.davido.vn`,
      apiUrl: `https://youmed.test.davido.vn/api`,
      adminApiUrl: 'https://youmed.test.davido.vn/admin/api',
      returnUrlHome: 'https://youmed.test.davido.vn',
      returnUrlBooking: 'https://youmed.test.davido.vn/dat-kham',
      jsonUrl: 'https://youmed.test.davido.vn/dat-kham/assets/json',
      urlSocket: 'https://youmed.test.davido.vn',
      chatDomain: 'https://chat.test.davido.vn',
    };
    break;
  case 'youmed.vn':
    listUrlApi = {
      isShareAuthOrLang: true,
      url: `https://youmed.vn`,
      cdn: `https://cdn.youmed.vn`,
      apiUrl: `https://youmed.vn/api`,
      adminApiUrl: `https://youmed.vn/admin/api`,
      returnUrlHome: 'https://youmed.vn',
      returnUrlBooking: 'https://youmed.vn/dat-kham',
      jsonUrl: 'https://youmed.vn/dat-kham/assets/json',
      urlSocket: 'https://youmed.vn',
      chatDomain: 'https://chat.youmed.vn',
    };
    break;

  default:
    listUrlApi = {
      isShareAuthOrLang: false,
      url: `https://youmed.test.davido.vn`,
      cdn: `https://cdn.test.davido.vn`,
      apiUrl: `https://youmed.test.davido.vn/api`,
      adminApiUrl: `https://youmed.test.davido.vn/admin/api`,
      returnUrlHome: 'https://youmed.test.davido.vn',
      returnUrlBooking: 'https://youmed.test.davido.vn/dat-kham',
      jsonUrl: 'https://youmed.test.davido.vn/dat-kham/assets/json',
      urlSocket: 'https://youmed.test.davido.vn',
      chatDomain: 'https://chat.youmed.vn',
    };
    break;
}

export const environment = {
  production: true,
  api: {
    baseUrl: './',
    refreshTokenEnabled: false,
    refreshTokenType: 'auth-refresh',
  },
  cdn: listUrlApi.cdn,
  isShareAuthOrLang: listUrlApi?.isShareAuthOrLang,
  isXYM_APP_WEB: true,
  showPromptUpdate: false,
  url: listUrlApi?.url,
  apiUrl: listUrlApi?.apiUrl,
  adminApiUrl: listUrlApi?.adminApiUrl,
  returnUrlHome: listUrlApi?.returnUrlHome,
  returnUrlBooking: listUrlApi?.returnUrlBooking,
  jsonUrl: listUrlApi?.jsonUrl,
  urlSocket: listUrlApi?.urlSocket,
  googleMapKey: 'AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8',
  chatDomain: listUrlApi?.chatDomain,
};
