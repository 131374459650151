<footer class="relative site-footer bg-gray-50 py-3">
  <div hideBySlug class="max-w-7xl mx-auto grid grid-cols-12 gap-4 md:pt-10">
    <div
      class="px-4 py-6 text-sm col-span-12 sm:col-span-6 lg:col-span-4 space-y-2">
      <p class="font-semibold">{{ 'footer.company' | translate }}</p>
      <p>
        <strong>{{ 'footer.representative' | translate }}:</strong>
        3/1 Thành Thái, P. 14, Q.10, TP. HCM
      </p>
      <p>
        <strong>Hotline: </strong>
        <a href="tel:19002805" class="font-medium hover:underline">1900-2805</a>
        (8:00 - 17:30 từ T2 đến T7)
      </p>
      <p>
        Số ĐKKD 0315268642 do Sở Kế hoạch và Đầu tư TP. Hồ Chí Minh cấp lần đầu
        ngày 14/09/2018.
      </p>
      <p>
        Chịu trách nhiệm nội dung:
        <a
          href="https://youmed.vn/tin-tuc/duong-anh-hoang/"
          class="hover:underline underline-offset-2 hover:text-primary font-medium">
          Dược sĩ Dương Anh Hoàng</a
        >.
      </p>
    </div>
    <div
      class="mx-4 lg:mx-0 border-b md:border-0 col-span-12 sm:col-span-6 lg:col-span-2">
      <label
        for="check-about-us"
        class="flex items-center justify-between cursor-pointer sm:cursor-auto py-6 text-sm font-semibold"
        >{{ 'footer.about' | translate }}
        <svg
          width="14"
          height="14"
          xmlns="http://www.w3.org/2000/svg"
          class="block md:hidden opacity-40"
          viewBox="0 0 512 512">
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="48"
            d="M112 184l144 144 144-144"></path>
        </svg>
      </label>
      <input
        class="check-button"
        type="checkbox"
        name="check-about-us"
        id="check-about-us" />
      <ul class="list-none p-0 block text-sm space-y-2 transition-all">
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.about_us }}" target="_blank">{{
            'footer.about.a1' | translate
          }}</a>
        </li>
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.co_founder }}" target="_blank">{{
            'footer.about.a2' | translate
          }}</a>
        </li>
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.recruit }}" target="_blank">
            {{ 'footer.about.a3' | translate }}</a
          >
        </li>
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.contact }}" target="_blank">
            {{ 'footer.about.a5' | translate }}</a
          >
        </li>
      </ul>
    </div>
    <div
      class="mx-4 lg:mx-0 border-b md:border-0 col-span-12 sm:col-span-6 lg:col-span-3">
      <label
        for="check-services"
        class="flex items-center justify-between cursor-pointer sm:cursor-auto py-6 text-sm font-semibold">
        {{ 'footer.service' | translate }}
        <svg
          width="14"
          height="14"
          xmlns="http://www.w3.org/2000/svg"
          class="block md:hidden opacity-40"
          viewBox="0 0 512 512">
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="48"
            d="M112 184l144 144 144-144"></path>
        </svg>
      </label>
      <input
        class="check-button"
        type="checkbox"
        name="check-services"
        id="check-services" />
      <ul class="list-none text-sm p-0 block space-y-2 transition-all">
        <li class="hover:underline">
          <a routerLink="/{{ NAVIGATION.BASE.booking_doctor }}">
            {{ 'header.booking.doctor' | translate }}</a
          >
        </li>
        <li class="hover:underline">
          <a routerLink="/{{ NAVIGATION.BASE.booking_doctor }}">
            {{ 'header.booking.hospital' | translate }}</a
          >
        </li>
        <li class="hover:underline">
          <a routerLink="/{{ NAVIGATION.BASE.booking_doctor }}">
            {{ 'header.booking.clinic' | translate }}</a
          >
        </li>
        <li class="hover:underline">
          <a [href]="NAVIGATION.YOUMED.store" target="_blank"> YouMed Store</a>
        </li>
        <li class="hover:underline">
          <a [href]="NAVIGATION.YOUMED.y360" target="_blank">Y360</a>
        </li>
        <li class="hover:underline">
          <a [href]="NAVIGATION.YOUMED.clinic" target="_blank">YouMed Clinic</a>
        </li>
      </ul>
    </div>
    <div
      class="mx-4 lg:mx-0 border-b md:border-0 col-span-12 sm:col-span-6 lg:col-span-3">
      <label
        for="check-more"
        class="flex items-center justify-between cursor-pointer sm:cursor-auto py-6 text-sm font-semibold">
        {{ 'footer.help' | translate }}
        <svg
          width="14"
          height="14"
          xmlns="http://www.w3.org/2000/svg"
          class="block md:hidden opacity-40"
          viewBox="0 0 512 512">
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="48"
            d="M112 184l144 144 144-144"></path>
        </svg>
      </label>
      <input
        class="check-button"
        type="checkbox"
        name="check-more"
        id="check-more" />
      <ul class="text-sm space-y-2 block text-gray-600 transition-all">
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.qa }}" target="_blank">{{
            'footer.help.a1' | translate
          }}</a>
        </li>
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.rules }}" target="_blank">{{
            'footer.help.a2' | translate
          }}</a>
        </li>
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.security }}" target="_blank">{{
            'footer.help.a3' | translate
          }}</a>
        </li>
        <li class="hover:underline">
          <a href="{{ NAVIGATION.YOUMED.complain }}" target="_blank">{{
            'footer.help.a4' | translate
          }}</a>
        </li>
        <li>
          {{ 'footer.help.a5' | translate }}:
          <a href="mailto:cskh@youmed.vn" class="hover:underline"
            >cskh@youmed.vn</a
          >
        </li>
      </ul>
    </div>
    <div class="px-4 py-6 md:py-0 text-sm col-span-12 md:col-span-4 space-y-2">
      <div class="cursor-pointer md:cursor-auto text-sm font-semibold">
        {{ 'footer.connect' | translate }}
      </div>
      <ul class="flex space-x-3 items-center justify-center md:justify-start">
        <li>
          <a href="{{ NAVIGATION.SOCIAL.facebook }}" target="_blank">
            <img
              src="./assets/img/booking/svg/facebook.svg"
              width="24"
              height="24"
              loading="lazy"
              alt="Facebook" />
          </a>
        </li>
        <li>
          <a href="{{ NAVIGATION.SOCIAL.youtube }}" target="_blank">
            <img
              src="./assets/img/booking/svg/youtube.svg"
              width="24"
              height="24"
              loading="lazy"
              alt="YouTube" />
          </a>
        </li>
        <li>
          <a href="{{ NAVIGATION.SOCIAL.linkin }}" target="_blank">
            <img
              src="./assets/img/booking/svg/linkedin.svg"
              width="24"
              height="24"
              loading="lazy"
              alt="Linkedin" />
          </a>
        </li>
        <li>
          <a href="{{ NAVIGATION.SOCIAL.zalo }}" target="_blank">
            <img
              src="./assets/img/booking/svg/zalo.svg"
              width="36"
              height="16"
              loading="lazy"
              alt="Zalo" />
          </a>
        </li>
      </ul>
    </div>
    <div class="col-span-12 md:col-span-8 px-4 py-6 md:p-6">
      <ul class="flex space-x-4 items-center justify-center md:justify-end">
        <li>
          <a
            href="{{ NAVIGATION.CERTIFICATE.bo_cong_thuong }}"
            target="_blank"
            title="Đã đăng ký Bộ Công Thương">
            <img
              src="./assets/img/booking/svg/dadangky.svg"
              width="79"
              height="30"
              loading="lazy"
              alt="Đã đăng ký Bộ Công Thương" />
          </a>
        </li>
        <li>
          <a
            href="{{ NAVIGATION.CERTIFICATE.dmca }}https://youmed.vn/dat-kham{{
              href
            }}"
            target="_blank"
            title="DMCA.com Protection Status. Click to verify.">
            <img
              src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-04.png"
              loading="lazy"
              width="60"
              height="30"
              alt="DMCA.com Protection Status. Click to verify." />
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="max-w-7xl text-xs text-center mx-auto md:border-t border-slate-100 py-6 px-4">
    <p>
      Các thông tin trên YouMed chỉ dành cho mục đích tham khảo, tra cứu và
      không thay thế cho việc chẩn đoán hoặc điều trị y khoa.<br
        class="hidden lg:block" />
      Cần tuyệt đối tuân theo hướng dẫn của Bác sĩ và Nhân viên y tế.
    </p>
    <p class="mt-1">
      Copyright &copy; 2018 - {{ currentYear }} Công ty TNHH YouMed Việt Nam.
    </p>
  </div>
</footer>
