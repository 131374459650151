import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NAVIGATION } from './core/constant/route.constants';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutBasicComponent } from './layout/basic/basic.component';

const routes: Routes = [
  {
    path: `bac-si/${NAVIGATION.BASE.search}`,
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctor-search/doctor-search.module').then(
        m => m.DoctorSearchModule
      ),
  },
  {
    path: 'bac-si/:slug',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctor-home-slug/doctor-home-slug.module').then(
        m => m.DoctorHomeSlugModule
      ),
  },
  {
    path: `bac-si/:slug/${NAVIGATION.BASE.book}`,
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctor-booking/doctor-booking.module').then(
        m => m.DoctorBookingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: `bac-si/:slug/ket-qua-dat-kham`,
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctor-result/doctor-result.module').then(
        m => m.DoctorResultModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: `bac-si/:slug/ket-qua`,
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctor-result/doctor-result.module').then(
        m => m.DoctorResultModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'bac-si',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctors/doctors.module').then(x => x.DoctorsModule),
  },
  {
    path: 'logout',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/user/logout/logout.module').then(x => x.LogoutModule),
  },
  {
    path: 'register',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/user/register/register.module').then(
        x => x.RegisterModule
      ),
  },
  {
    path: 'forgot-password',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/user/forgot-password/forgot-password.module').then(
        x => x.ForgotPassWordModule
      ),
  },
  {
    path: 'search',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/search/search.module').then(x => x.SearchModule),
  },
  {
    path: 'chi-tiet-bac-si',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/doctors/doctors.module').then(x => x.DoctorsModule),
  },
  {
    path: 'bv',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/hospitals/hospitals.module').then(
        x => x.HospitalsModule
      ),
  },
  {
    path: 'benh-vien',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/hospitals/hospitals.module').then(
        x => x.HospitalsModule
      ),
  },
  {
    path: 'chi-tiet-benh-vien',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/hospitals/hospitals.module').then(
        x => x.HospitalsModule
      ),
  },
  {
    path: 'phong-kham',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/clinics/clinics.module').then(x => x.ClinicsModule),
  },
  {
    path: 'chi-tiet-phong-kham',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/clinics/clinics.module').then(x => x.ClinicsModule),
  },
  {
    path: 'xet-nghiem',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/labs-v2/labs-v2.module').then(x => x.LabsV2Module),
  },
  {
    path: 'tiem-chung',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/vaccines/vaccines.module').then(x => x.VaccinesModule),
  },
  {
    path: 'ho-so',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/patient-record/patient-record.module').then(
        x => x.PatientRecordModule
      ),
  },
  {
    path: 'lich-kham',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/appointment-history/appointment-history.module').then(
        x => x.AppointmentHistoryModule
      ),
  },
  {
    path: 'tai-khoan',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/account/account.module').then(x => x.AccountModule),
  },
  {
    path: 'thanh-toan',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/payment-history/payment-history.module').then(
        x => x.PaymentHistoryModule
      ),
  },
  {
    path: 'no-internet',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/no-internet/no-internet.module').then(
        x => x.NoInternetModule
      ),
  },
  {
    path: 'login',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/user/login/login.module').then(x => x.LoginModule),
  },
  {
    path: '',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/home/home.module').then(x => x.HomeModule),
  },
  {
    path: '**',
    component: LayoutBasicComponent,
    loadChildren: () =>
      import('./routes/error/error.module').then(x => x.ErrorModule),
  },
  // {
  //   path: '**',
  //   component: LayoutBasicComponent,
  //   loadChildren: () =>
  //     import('./routes/error/error.module').then(x => x.ErrorModule),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'top',
      // preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/dat-kham/' }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
